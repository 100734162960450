import api from "./index"

/**
 * Get all publishedComments
 *
 * @param params
 * @param includes 
 * @return {*}
 */

export const getAllDeletedComments = params => {
  return api
    .request("admin/comments/trashed")
    .withParams(params)
    .get()
}
